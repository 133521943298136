<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('incentiveAllocation.upazila_allocation') }}</h4>
                  </template>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form1"  v-slot="{ handleSubmit, reset }">
                                        <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                                            <div class="row">
                                                 <template>
                                                    <div class="col-sm-12 col-md-12">
                                                      <div style="font-size:12px; background-color: #337982; padding:6px">
                                                        <h5 class="text-white text-center"> {{ $t('admission_form.gen_inf') }}</h5>
                                                      </div>
                                                    </div>
                                                </template>
                                                <div class="col-sm-6 col-md-6 mt-3">
                                                    <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="fiscal_year_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('dae_grant_allocation_distribution.fiscal_year')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="form.fiscal_year_id"
                                                            :options="fiscalList"
                                                            id="fiscal_year_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            @change="getCircularList"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-md-6 mt-3">
                                                    <ValidationProvider name="Season" vid="season_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="season_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('dae_grant_allocation_distribution.season')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="form.season_id"
                                                            :options="seasonSetupList"
                                                            id="season_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            @change="getCircularList"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-md-6">
                                                    <ValidationProvider name="Circular Type" vid="circular_type" rules="required|min_value:1">
                                                        <b-form-group
                                                          class="row"
                                                          label-cols-sm="4"
                                                          label-for="circular_type"
                                                          slot-scope="{ valid, errors }"
                                                        >
                                                          <template v-slot:label>
                                                            {{ $t('dae_subsidy.circular_type') }} <span class="text-danger">*</span>
                                                          </template>
                                                          <b-form-select
                                                              id="circular_type"
                                                              plain
                                                              v-model="form.circular_type"
                                                              :options="circularTypeList"
                                                              :state="errors[0] ? false : (valid ? true : null)"
                                                              @change="getCircularList"
                                                              >
                                                              <template v-slot:first>
                                                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                              </template>
                                                          </b-form-select>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-md-6">
                                                    <ValidationProvider name="Circular" vid="circular_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="circular_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('dae_config.circular_name')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="form.circular_id"
                                                            :options="circularList"
                                                            id="subsidy_type_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-md-6" v-if="form.circular_type !==4">
                                                    <ValidationProvider name="Crop" vid="crop_id" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="crop_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('dae_config.crop_name')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="form.crop_id"
                                                            :options="crops"
                                                            id="subsidy_type_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            @change="getDistributionPolicyInfo"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-md-6">
                                                  <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="district_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                      <template v-slot:label>
                                                        {{$t('org_pro_district.district')}} <span class="text-danger">*</span>
                                                      </template>
                                                      <b-form-select
                                                          plain
                                                          v-model="form.district_id"
                                                          :options="districtList"
                                                          :disabled="isReadOnly"
                                                          id="district_id"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </b-form-group>
                                                  </ValidationProvider>
                                                </div>
                                                <div class="col-sm-6 col-md-6">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search')}}</b-button>
                                                </div>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                    <ValidationObserver ref="form2"  v-slot="{ handleSubmit, reset }" v-if="showData">
                                        <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                            <div class="row mt-3" v-if="form.circular_type !== 4">
                                              <div class="col-sm-12 col-md-12">
                                                <div style="font-size:12px; background-color: #337982; padding:6px">
                                                  <h5 class="text-white text-center"> {{ $t('incentiveAllocation.upazila_allocation') }}</h5>
                                                </div>
                                              </div>
                                              <div class="col-sm-12 col-md-12">
                                                <div class="table-responsive">
                                                  <b-table-simple striped bordered small class="mt-3" v-if="form.district_id !== 0">
                                                    <b-thead>
                                                      <b-tr>
                                                        <b-th rowspan="4" class="text-center align-top">{{ $t('globalTrans.sl_no') }}</b-th>
                                                        <b-th rowspan="4" class="text-center align-top">{{ $t('org_pro_district.district') }}</b-th>
                                                        <b-th :colspan="4+(materials.length*2)" class="text-center">{{ $t('rehabilitaionAllocation.crop_fertilizer') }}</b-th>
                                                        <b-th rowspan="4" class="text-center align-top">{{ $t('rehabilitaionAllocation.cash_tk') }}</b-th>
                                                        <b-th rowspan="4" class="text-center align-top">{{ $t('rehabilitaionAllocation.transport_cost') }}</b-th>
                                                        <b-th rowspan="4" class="text-center align-top">{{ $t('rehabilitaionAllocation.other_cost') }}</b-th>
                                                        <b-th rowspan="4" class="text-center align-top">{{ $t('globalTrans.totalAmount') }} {{ $t('rehabilitaionAllocation.tk') }}</b-th>
                                                      </b-tr>
                                                      <b-tr>
                                                        <b-th colspan="2" class="text-center text-capitalize"></b-th>
                                                        <b-th colspan="2" class="text-center text-capitalize">{{ currentLocale === 'en' ? form.crop_name : form.crop_name_bn }}</b-th>
                                                        <b-th :colspan="materials.length * 2" class="text-center">{{ $t('rehabilitaionAllocation.fertilizer') }}</b-th>
                                                      </b-tr>
                                                      <b-tr>
                                                        <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.no_of_farmer') }}</b-th>
                                                        <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.land_qty') }}</b-th>
                                                        <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.crop_qty') }}</b-th>
                                                        <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.crop_amount') }}</b-th>
                                                        <b-th colspan="2" class="text-center text-capitalize" v-for="(material, materialIndex) in materials" :key="materialIndex">{{ currentLocale === 'en' ? material.material_name : material.material_name_bn }}</b-th>
                                                      </b-tr>
                                                      <b-tr>
                                                        <template v-for="(matirial, matirialIndex) in materials">
                                                          <b-th class="text-center" :key="matirialIndex+200">{{ $t('rehabilitaionAllocation.qty') }}</b-th>
                                                          <b-th class="text-center" :key="matirialIndex+400">{{ $t('globalTrans.amount') }}</b-th>
                                                        </template>
                                                      </b-tr>
                                                    </b-thead>
                                                    <b-tbody>
                                                      <tr>
                                                        <td> {{ $n(1) }} </td>
                                                        <td class="text-capitalize">{{ currentLocale === 'bn' ? districtAllocation.district_name_bn : districtAllocation.district_name }}</td>
                                                        <td class="text-right">
                                                           {{ $n(districtAllocation.total_farmer, { useGrouping: false })}}
                                                        </td>
                                                        <td class="text-right">
                                                           {{ $n(districtAllocation.land_quantity, { useGrouping: false })}}
                                                        </td>
                                                        <td class="text-right">
                                                          {{ $n(districtAllocation.crop_quantity, { useGrouping: false }) }}
                                                        </td>
                                                        <td class="text-right">
                                                          {{ $n(districtAllocation.crop_amount, { useGrouping: false }) }}
                                                        </td>
                                                        <template v-for="(item, index1) in districtAllocation.materials">
                                                          <td :key="1+100+index1+100" class="text-right">
                                                            {{ $n(item.quantity, { useGrouping: false }) }}
                                                          </td>
                                                          <td :key="1+200+index1+200" class="text-right">
                                                            {{ $n(item.amount, { useGrouping: false }) }}
                                                          </td>
                                                        </template>
                                                        <td class="text-right">
                                                          {{ $n(districtAllocation.cash_tk, { useGrouping: false }) }}
                                                        </td>
                                                        <td class="text-right">
                                                          {{ $n(districtAllocation.transport_cost, { useGrouping: false }) }}
                                                        </td>
                                                        <td class="text-right">
                                                          {{ $n(districtAllocation.other_cost, { useGrouping: false }) }}
                                                        </td>
                                                        <td class="text-right">
                                                          {{ $n(districtAllocation.total_amount, { useGrouping: false }) }}
                                                        </td>
                                                      </tr>
                                                    </b-tbody>
                                                  </b-table-simple>
                                                </div>
                                                <div class="table-responsive">
                                                  <b-table-simple striped bordered small class="mt-3">
                                                    <b-thead>
                                                      <b-tr>
                                                        <b-th rowspan="4" class="text-center align-top">{{ $t('globalTrans.sl_no') }}</b-th>
                                                        <b-th rowspan="4" class="text-center align-top">{{ $t('globalTrans.upazila') }}</b-th>
                                                        <b-th :colspan="4+(materials.length*2)" class="text-center">{{ $t('rehabilitaionAllocation.crop_fertilizer') }}</b-th>
                                                        <b-th rowspan="4" class="text-center align-top">{{ $t('rehabilitaionAllocation.cash_tk') }}</b-th>
                                                        <b-th rowspan="4" class="text-center align-top">{{ $t('rehabilitaionAllocation.transport_cost') }}</b-th>
                                                        <b-th rowspan="4" class="text-center align-top">{{ $t('rehabilitaionAllocation.other_cost') }}</b-th>
                                                        <b-th rowspan="4" class="text-center align-top">{{ $t('globalTrans.totalAmount') }} {{ $t('rehabilitaionAllocation.tk') }}</b-th>
                                                      </b-tr>
                                                      <b-tr>
                                                        <b-th colspan="2" class="text-center text-capitalize"></b-th>
                                                        <b-th colspan="2" class="text-center text-capitalize">{{ currentLocale === 'en' ? form.crop_name : form.crop_name_bn }}</b-th>
                                                        <b-th :colspan="materials.length * 2" class="text-center">{{ $t('rehabilitaionAllocation.fertilizer') }}</b-th>
                                                      </b-tr>
                                                      <b-tr>
                                                        <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.no_of_farmer') }}</b-th>
                                                        <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.land_qty') }}</b-th>
                                                        <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.qty') }}</b-th>
                                                        <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.amount') }}</b-th>
                                                        <b-th colspan="2" class="text-center text-capitalize" v-for="(material, materialIndex) in materials" :key="materialIndex">{{ currentLocale === 'en' ? material.material_name : material.material_name_bn }}</b-th>
                                                      </b-tr>
                                                      <b-tr>
                                                        <template v-for="(matirial, matirialIndex) in materials">
                                                          <b-th class="text-center" :key="matirialIndex+200">{{ $t('rehabilitaionAllocation.qty') }}</b-th>
                                                          <b-th class="text-center" :key="matirialIndex+400">{{ $t('rehabilitaionAllocation.amount') }}</b-th>
                                                        </template>
                                                      </b-tr>
                                                    </b-thead>
                                                    <b-tbody>
                                                      <tr v-for="(detail, index) in form.details" :key="index">
                                                        <td>{{ $n(index + 1) }}</td>
                                                        <td class="text-capitalize">{{ currentLocale === 'bn' ? detail.upazilla_name_bn : detail.upazilla_name }}</td>
                                                        <td>
                                                          <ValidationProvider name="No Of Farmer" :vid="`total_farmer${index}`">
                                                            <b-form-input
                                                                type="text"
                                                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                plain
                                                                v-model="detail.total_farmer"
                                                                :id="`total_farmer${index}`"
                                                                @input="calculateAssociateInfo(index)"
                                                            >
                                                            </b-form-input>
                                                          </ValidationProvider>
                                                        </td>
                                                        <td class="text-right">
                                                          {{ $n(detail.land_quantity, { useGrouping: false }) }}
                                                        </td>
                                                        <td class="text-right">
                                                          {{ $n(detail.crop_quantity, { useGrouping: false }) }}
                                                        </td>
                                                        <td class="text-right">
                                                          {{ $n(detail.crop_amount, { useGrouping: false }) }}
                                                        </td>
                                                        <template v-for="(item, index1) in detail.materials">
                                                          <td :key="index+100+index1+100" class="text-right">
                                                            <ValidationProvider name="Quantity" :vid="'details_'+index+'_quantity_'+index1">
                                                              <b-form-input
                                                                  :id="'details_'+index+'_quantity_'+index1"
                                                                  :ref="'details_'+index+'_quantity_'+index1"
                                                                  type="text"
                                                                  step="any"
                                                                  v-model="form.details[index].materials[index1].quantity"
                                                                  @input="calculateAssociateInfo(index, index1)"
                                                              ></b-form-input>
                                                            </ValidationProvider>
                                                          </td>
                                                          <td :key="index+200+index1+200" class="text-right">
                                                            {{ $n(form.details[index].materials[index1].amount, { useGrouping: false }) }}
                                                          </td>
                                                        </template>
                                                        <td class="text-right">{{ $n(detail.cash_tk, { useGrouping: false }) }}</td>
                                                        <td class="text-right">
                                                          {{ $n(detail.transport_cost, { useGrouping: false }) }}
                                                        </td>
                                                        <td class="text-right">
                                                          {{ $n(detail.other_cost, { useGrouping: false }) }}
                                                        </td>
                                                        <td class="text-right">
                                                          {{ $n(detail.total_amount, { useGrouping: false }) }}
                                                        </td>
                                                      </tr>
                                                    </b-tbody>
                                                    <b-tfoot>
                                                      <b-tr>
                                                        <b-th colspan="2" class="text-right">{{ $t('globalTrans.total') }}</b-th>
                                                        <b-th class="text-right">
                                                          {{ $n(totalItem.total_farmer, { useGrouping: false }) }}
                                                        </b-th>
                                                        <b-th class="text-right">
                                                          {{  $n(totalItem.land_quantity, { useGrouping: false }) }}
                                                        </b-th>
                                                        <b-th class="text-right">
                                                          {{ $n(totalItem.crop_quantity, { useGrouping: false }) }}
                                                        </b-th>
                                                        <b-th class="text-right">
                                                          {{ $n(totalItem.crop_amount, { useGrouping: false }) }}
                                                        </b-th>
                                                        <template v-for="(item, materialIndex) in totalItem.materials">
                                                          <b-th :key="5000+materialIndex+100" class="text-right">
                                                            {{ $n(item.quantity, { useGrouping: false }) }}
                                                          </b-th>
                                                          <b-th :key="2000+materialIndex+200" class="text-right">
                                                            {{ $n(item.amount, { useGrouping: false }) }}
                                                          </b-th>
                                                        </template>
                                                        <b-th class="text-right">
                                                          {{ $n(totalItem.cash_tk, { useGrouping: false }) }}
                                                        </b-th>
                                                        <b-th class="text-right">
                                                          {{ $n(totalItem.transport_cost, { useGrouping: false }) }}
                                                        </b-th>
                                                        <b-th class="text-right">
                                                          {{ $n(totalItem.other_cost, { useGrouping: false }) }}
                                                        </b-th>
                                                        <b-th class="text-right">
                                                          {{ $n(totalItem.total_amount, { useGrouping: false }) }}
                                                        </b-th>
                                                      </b-tr>
                                                    </b-tfoot>
                                                  </b-table-simple>
                                                </div>
                                              </div>
                                            </div>
                                            <!--  Financial Aid Info  -->
                                            <div class="row mt-3" v-else>
                                              <div class="col-sm-12 col-md-12">
                                                <div style="font-size:12px; background-color: #337982; padding:6px">
                                                  <h5 class="text-white text-center"> {{ $t('dae_grant_allocation_distribution.allocation_info') }}</h5>
                                                </div>
                                                <div class="table-responsive">
                                                  <b-table-simple striped bordered small class="mt-3" v-if="form.district_id !== 0">
                                                    <b-thead>
                                                      <b-tr>
                                                        <b-th class="text-center align-top">{{ $t('org_pro_district.district') }}</b-th>
                                                        <b-th class="text-center">{{ $t('rehabilitaionAllocation.no_of_farmer') }}</b-th>
                                                        <b-th class="text-center">{{ $t('globalTrans.amount') }} {{ $t('globalTrans.tk') }}</b-th>
                                                      </b-tr>
                                                    </b-thead>
                                                    <b-tbody>
                                                      <tr>
                                                        <td class="text-capitalize text-center">{{ currentLocale === 'bn' ? districtAllocation.district_name_bn : districtAllocation.district_name }}</td>
                                                        <td class="text-right">
                                                          {{ $n(districtAllocation.total_farmer, { useGrouping: false }) }}
                                                        </td>
                                                        <td class="text-right">
                                                          {{ $n(districtAllocation.total_amount, { useGrouping: false }) }}
                                                        </td>
                                                      </tr>
                                                    </b-tbody>
                                                  </b-table-simple>
                                                </div>
                                                <div class="table-responsive">
                                                  <b-table-simple striped bordered small class="mt-3">
                                                    <b-thead>
                                                      <b-tr>
                                                        <b-th class="text-center align-top">{{ $t('globalTrans.sl_no') }}</b-th>
                                                        <b-th class="text-center align-top">{{ $t('globalTrans.upazila') }}</b-th>
                                                        <b-th rowspan="2" class="text-center">{{ $t('rehabilitaionAllocation.no_of_farmer') }}</b-th>
                                                        <b-th class="text-center">{{ $t('globalTrans.amount') }} {{ $t('globalTrans.tk') }}</b-th>
                                                      </b-tr>
                                                    </b-thead>
                                                    <b-tbody>
                                                      <tr v-for="(detail, index) in form.details" :key="index">
                                                        <td class="text-center">{{ $n(index + 1) }}</td>
                                                        <td class="text-capitalize text-center">{{ currentLocale === 'bn' ? detail.upazilla_name_bn : detail.upazilla_name }}</td>
                                                        <td>
                                                          <ValidationProvider name="No Of Farmer" :vid="`total_farmer${index}`">
                                                            <b-form-input
                                                                type="text"
                                                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                plain
                                                                v-model="detail.total_farmer"
                                                                :id="`total_farmer${index}`"
                                                                @input="calculateAssociateInfo2(index)"
                                                            >
                                                            </b-form-input>
                                                          </ValidationProvider>
                                                        </td>
                                                        <td>
                                                          <ValidationProvider name="Amount" :vid="`amount${index}`">
                                                            <b-form-input
                                                                type="number"
                                                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                v-model="detail.total_amount"
                                                                :id="`amount${index}`"
                                                                disabled
                                                            >
                                                            </b-form-input>
                                                          </ValidationProvider>
                                                        </td>
                                                      </tr>
                                                    </b-tbody>
                                                    <b-tfoot>
                                                      <b-tr>
                                                        <b-th colspan="2" class="text-right">{{ $t('globalTrans.total') }}</b-th>
                                                        <b-th class="text-right">
                                                          {{ $n(totalItem.total_farmer, { useGrouping: false }) }}
                                                        </b-th>
                                                        <b-th class="text-right">
                                                          {{ $n(totalItem.total_amount, { useGrouping: false }) }}
                                                        </b-th>
                                                      </b-tr>
                                                    </b-tfoot>
                                                  </b-table-simple>
                                                </div>
                                              </div>
                                            </div>
                                            <!--  /Financial Aid Info  -->
                                            <div class="row">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveUpdate }}</b-button>
                                                    &nbsp;
                                                    <router-link to="/incentive-grant-service/dae/allocation/upazila-allocation" :class="'btn btn-danger text-light'">
                                                        {{ $t('globalTrans.cancel') }}
                                                    </router-link>
                                                </div>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import {
  upazillaAlloStore,
  upazillaAlloShow,
  upazillaAlloUpdate,
  circularCropMaterials,
  districtAllocationData,
  distAlloPolicyInfo
} from '../../api/routes'

export default {
    components: {
      ValidationObserver,
      ValidationProvider
    },
    data () {
      return {
        id: this.$route.query.id,
        loading: false,
        crops: [],
        districtAllocation: {},
        isReadOnly: false,
        form: {
          fiscal_year_id: 0,
          season_id: 0,
          circular_type: 0,
          circular_id: 0,
          crop_id: 0,
          district_id: 0,
          division_id: 0,
          details: []
        },
        circularList: [],
        totalAmountColumnText: '',
        policy: {},
        distributionPolicyCropInfo: {},
        distributionPolicyMatInfo: [],
        showData: false,
        totalItem: {
          total_farmer: '',
          land_quantity: '',
          crop_quantity: '',
          crop_amount: '',
          material_quantity: '',
          material_amount: '',
          cash_tk: '',
          transport_cost: '',
          other_cost: '',
          total_amount: '',
          materials: []
        }
      }
    },
    created () {
      if (this.id) {
        this.getFormData()
      }
    },
    watch: {
      'form.circular_id': function (newVal, oldVal) {
          if (newVal !== oldVal) {
            if (this.form.circular_type !== 4) {
              this.getCropAndMaterials(newVal)
            }
          }
      },
      'form.crop_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const crop = this.$store.state.incentiveGrant.commonObj.cropList.find(item => item.value === parseInt(newVal))
          this.form.crop_name = crop?.text_en
          this.form.crop_name_bn = crop?.text_bn
          // this.getDistributionPolicyInfo()
        }
      }
    },
    mounted () {
      core.index()
      if (this.authUser.role_id !== 1 && this.authUser.is_org_admin === 0) {
        this.isReadOnly = true
        this.form.district_id = this.authUser.office_detail.district_id
      }
    },
    computed: {
      saveUpdate: function () {
        return this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save')
      },
      authUser: function () {
        return this.$store.state.Auth.authUser
      },
      currentLocale: function () {
        return this.$i18n.locale
      },
      seasonSetupList: function () {
        return this.$store.state.incentiveGrant.commonObj.seasonList.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
        })
      },
      districtList: function () {
        return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      },
      projectList: function () {
          const list = this.$store.state.incentiveGrant.commonObj.projectList.filter(item => item.status === 1)
          return list.map(item => {
              if (this.$i18n.locale === 'bn') {
                  return { value: item.value, text: item.text_bn }
              } else {
                  return { value: item.value, text: item.text_en }
              }
          })
      },
      fiscalList: function () {
          return this.$store.state.commonObj.fiscalYearList
      },
      circularTypeList: function () {
        const dataList = this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList.filter(ct => ct.value !== 1)
        return dataList.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text_en }
          }
        })
      }
    },
    methods: {
      async getFormData () {
        this.loading = true
        const result = await RestApi.getData(incentiveGrantServiceBaseUrl, `${upazillaAlloShow}/${this.id}`)
        if (result.success) {
          this.form = result.data
          this.form.details = result.data.details
          this.getCircularList()
          // this.getCropAndMaterials(result.data.circular_id)
          if (this.form.circular_type === 4) {
            this.searchData()
          }
        } else {
          this.form.details = []
          this.loading = false
        }
      },
      async searchData () {
        this.loading = true
        const district = this.districtList.find(item => item.value === parseInt(this.form.district_id))
        this.form.division_id = district.division_id
        this.districtWiseUpazilla(this.form.district_id)
        const params = {}
        Object.keys(this.form).map(key => {
          if (key === 'fiscal_year_id' || key === 'season_id' || key === 'circular_id' || key === 'crop_id' || key === 'district_id') {
              params[key] = this.form[key]
          }
        })
        await RestApi.getData(incentiveGrantServiceBaseUrl, districtAllocationData, params)
        .then(response => {
            if (response.success) {
              if (this.form.circular_type !== 4) {
                if (this.distributionPolicyCropInfo.crop_id && this.distributionPolicyMatInfo.length) {
                  this.showData = true
                } else {
                  this.showData = false
                }
              } else {
                this.showData = true
              }
              this.districtAllocation = this.relationalData(response.data)
              this.loading = false
            } else {
              this.showData = false
              this.loading = false
            }
        })
      },
      relationalData (data) {
        const districtData = this.$store.state.commonObj.districtList.find(item => item.status === 0 && item.value === data.district_id)
        const tmpDetails = {
            district_name: districtData.text_en,
            district_name_bn: districtData.text_bn
        }
        return Object.assign(data, tmpDetails)
      },
      districtWiseUpazilla (districtId) {
        const upazillaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0 && item.district_id === districtId)
        const temp = []
        upazillaList.forEach(item => {
          const upazilaItem = this.form.details.find(detailsItem => detailsItem.upazilla_id === item.value)
          const materialArr = []
          if (this.form.circular_type !== 4) {
            this.materials.forEach(materialItem => {
              const upazilaMaterialItem = upazilaItem?.materials.find(materialObj => materialObj.cir_mat_id === materialItem.id)
              materialArr.push({
                cir_mat_id: materialItem.id,
                quantity: typeof upazilaMaterialItem !== 'undefined' ? upazilaMaterialItem.quantity : '',
                amount: typeof upazilaMaterialItem !== 'undefined' ? upazilaMaterialItem.amount : ''
              })
            })
          }
          const tmpDetails = {
            upazilla_name: item.text_en,
            upazilla_name_bn: item.text_bn,
            upazilla_id: item.value,
            total_farmer: typeof upazilaItem !== 'undefined' ? upazilaItem.total_farmer : '',
            land_quantity: typeof upazilaItem !== 'undefined' ? upazilaItem.land_quantity : '',
            crop_quantity: typeof upazilaItem !== 'undefined' ? upazilaItem.crop_quantity : '',
            crop_amount: typeof upazilaItem !== 'undefined' ? upazilaItem.crop_amount : '',
            material_quantity: typeof upazilaItem !== 'undefined' ? upazilaItem.material_quantity : '',
            material_amount: typeof upazilaItem !== 'undefined' ? upazilaItem.material_amount : '',
            cash_tk: typeof upazilaItem !== 'undefined' ? upazilaItem.cash_tk : '',
            transport_cost: typeof upazilaItem !== 'undefined' ? upazilaItem.transport_cost : '',
            other_cost: typeof upazilaItem !== 'undefined' ? upazilaItem.other_cost : '',
            total_amount: typeof upazilaItem !== 'undefined' ? upazilaItem.total_amount : '',
            materials: materialArr
          }
          temp.push(tmpDetails)
        })
        this.form.details = temp
        if (this.id) {
          if (this.form.circular_type !== 4) {
            this.getDistributionPolicyInfo()
          }
          this.getTotalRowInfo()
        }
        if (this.form.circular_type !== 4) {
          this.getTotalAmountColumnText()
        }
      },
      async saveData () {
        this.loading = true
        let result = null
        const district = this.districtList.find(item => item.value === parseInt(this.form.district_id))
        this.form.division_id = district.division_id
        const formData = Object.assign(this.form, { office_id: this.authUser.office_id })
        if (this.id) {
            result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${upazillaAlloUpdate}/${this.id}`, formData)
        } else {
            result = await RestApi.postData(incentiveGrantServiceBaseUrl, upazillaAlloStore, formData)
        }
        this.loading = false
        if (result.success) {
          this.$toast.success({
              title: this.$t('globalTrans.success'),
              message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
              color: '#D6E09B'
          })
          this.$router.push('/incentive-grant-service/dae/allocation/upazila-allocation')
        } else {
          if (result.errors) {
            this.$refs.form1.setErrors(result.errors)
            this.$refs.form2.setErrors(result.errors)
          } else {
            this.$toast.error({
              title: 'Error',
              message: result.message,
              color: '#D6E09B'
            })
          }
        }
      },
      async getCropAndMaterials (circularId) {
        this.loading = true
        await RestApi.getData(incentiveGrantServiceBaseUrl, `${circularCropMaterials}/${circularId}`)
        .then(response => {
          if (response.success) {
            this.crops = this.getCropData(response.data.crop_details)
            this.materials = this.getMaterialData(response.data.material_details)
            this.totalItem.materials = []
            this.materials.forEach(materialItem => {
              this.totalItem.materials.push({
                cir_mat_id: materialItem.id,
                quantity: '',
                amount: ''
              })
            })
            if (this.id) {
              this.searchData()
            } else {
              this.loading = false
            }
          } else {
            this.crops = []
            this.materials = []
            this.loading = false
          }
        })
      },
      async getDistributionPolicyInfo () {
        this.loading = true
        const materialIds = []
        this.materials.forEach(item => {
          materialIds.push(item.material_id)
        })
        const data = {
          fiscal_year_id: this.form.fiscal_year_id,
          season_id: this.form.season_id,
          circular_id: this.form.circular_id,
          crop_id: this.form.crop_id,
          material_ids: materialIds
        }
        const result = await RestApi.postData(incentiveGrantServiceBaseUrl, distAlloPolicyInfo, data)
        if (result.success) {
          this.policy = result.data
          this.distributionPolicyCropInfo = result.data.crop_details.find(item => item.crop_id === parseInt(this.form.crop_id))
          this.distributionPolicyMatInfo = result.data.mat_details
        } else {
          this.distributionPolicyCropInfo = {}
          this.distributionPolicyMatInfo = []
        }
        this.loading = false
        // if (this.distributionPolicyCropInfo.crop_id && this.distributionPolicyMatInfo.length) {
        //   this.showData = true
        // } else {
        //   this.showData = false
        // }
      },
      getCropData (data) {
        return data.map(item => {
            const crop = this.$store.state.incentiveGrant.commonObj.cropList.find(crp => crp.value === item.crop_id)
            return Object.assign(item, { value: crop.value, text: (this.currentLocale === 'bn') ? crop.text_bn : crop.text_en, amount: 0 })
        })
      },
      getMaterialData (data) {
          return data.map(item => {
              const material = this.$store.state.incentiveGrant.commonObj.agMaterialList.find(mt => mt.value === item.material_id)
              return Object.assign(item, { cir_mat_id: item.material_id, material_name: material.text_en, material_name_bn: material.text_bn, amount: 0 })
          })
      },
      getCircularList () {
        let circularList = []
        if (this.form.fiscal_year_id && this.form.season_id && this.form.circular_type) {
          circularList = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.fiscal_year_id === parseInt(this.form.fiscal_year_id) && item.circular_type === parseInt(this.form.circular_type))
          this.circularList = circularList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
          })
        } else {
          this.circularList = []
        }
        if (this.form.circular_type === 4) {
          this.form.crop_id = 0
        }
        // this.circularList = circularList
      },
      getTotalAmountColumnText () {
        let text = this.$n(4)
        this.materials.forEach((item, itemIndex) => {
          text += '+' + this.$n((((itemIndex + 1) * 2) + 4))
        })
        text += '+' + this.$n((parseInt((this.materials.length * 2)) + 5)) + '+' + this.$n((parseInt((this.materials.length * 2)) + 6))
        this.totalAmountColumnText = '(' + text + ')'
      },
      getTotalRowInfo () {
        let totalFarmer = 0
        let totalLandQty = 0
        let totalCropQty = 0
        let totalCropAmount = 0
        let totalCashTk = 0
        let totalTransportCost = 0
        let totalOtherCost = 0
        let totalAmount = 0
        const materialTotalArr = []
        if (this.form.circular_type !== 4) {
          this.materials.forEach(materialItem => {
            materialTotalArr.push({
              cir_mat_id: materialItem.id,
              quantity: 0,
              amount: 0
            })
          })
        }
        this.form.details.forEach((item, index) => {
          totalFarmer += item.total_farmer ? parseInt(item.total_farmer) : 0
          totalLandQty += item.land_quantity ? parseFloat(item.land_quantity) : 0
          totalCropQty += item.crop_quantity ? parseFloat(item.crop_quantity) : 0
          totalCropAmount += item.crop_amount ? parseFloat(item.crop_amount) : 0
          totalCashTk += item.cash_tk ? parseFloat(item.cash_tk) : 0
          totalTransportCost += item.transport_cost ? parseFloat(item.transport_cost) : 0
          totalOtherCost += item.other_cost ? parseFloat(item.other_cost) : 0
          totalAmount += item.total_amount ? parseFloat(item.total_amount) : 0
          if (this.form.circular_type !== 4) {
            item.materials.forEach((materialItem, materialIndex) => {
              materialTotalArr[materialIndex].quantity += materialItem.quantity ? parseFloat(materialItem.quantity) : 0
              materialTotalArr[materialIndex].amount += materialItem.amount ? parseFloat(materialItem.amount) : 0
            })
          }
        })
        this.totalItem.total_farmer = totalFarmer
        this.totalItem.land_quantity = totalLandQty
        this.totalItem.crop_quantity = totalCropQty
        this.totalItem.crop_amount = totalCropAmount
        this.totalItem.cash_tk = totalCashTk
        this.totalItem.transport_cost = totalTransportCost
        this.totalItem.other_cost = totalOtherCost
        this.totalItem.total_amount = totalAmount
        if (this.form.circular_type !== 4) {
          materialTotalArr.forEach((materialItem, materialIndex) => {
            this.totalItem.materials[materialIndex].quantity = materialItem.quantity
            this.totalItem.materials[materialIndex].amount = materialItem.amount
          })
        }
      },
      calculateAssociateInfo (index, materialIndex = null) {
        let totalNoOfFarmer = 0
        this.form.details.forEach(detailItem => {
          totalNoOfFarmer += detailItem.total_farmer ? parseInt(detailItem.total_farmer) : 0
        })
        if (totalNoOfFarmer > this.districtAllocation.total_farmer) {
          this.$toast.error({
            title: 'Error',
            message: 'Total farmer of upazila can not be larger than total farmer of district',
            color: '#D6E09B'
          })
          this.form.details[index].total_farmer = this.districtAllocation.total_farmer - (totalNoOfFarmer - this.form.details[index].total_farmer)
          this.processCalculateAssociateInfo(index)
          return false
        }
        // check material quantity
        let totalMaterialQty = 0
        this.form.details.forEach(detailItem => {
          let matQty = 0
          detailItem.materials.forEach(material => {
            matQty += material.quantity ? parseFloat(material.quantity) : 0
          })
          totalMaterialQty += matQty
        })
        if (totalMaterialQty > this.districtAllocation.material_quantity) {
          this.$toast.error({
            title: 'Error',
            message: 'Total material quantity of upazila can not be larger than total material quantity of district',
            color: '#D6E09B'
          })
          this.form.details[index].materials.forEach((material, key) => {
            this.form.details[index].materials[key].quantity = 0
          })
          this.processCalculateAssociateInfo(index)
          return false
        }
        this.processCalculateAssociateInfo(index)
      },
      processCalculateAssociateInfo (index) {
        const crop = this.$store.state.incentiveGrant.commonObj.cropList.find(crp => crp.value === parseInt(this.form.crop_id))
        const totalFarmer = parseFloat(this.form.details[index].total_farmer)
        this.form.details[index].land_quantity = totalFarmer
        const landQty = this.form.details[index].land_quantity ? parseFloat(this.form.details[index].land_quantity) : 0
        const cropQty = parseFloat(this.distributionPolicyCropInfo.qnty) * landQty
        this.form.details[index].crop_quantity = cropQty
        this.form.details[index].crop_amount = cropQty * crop.price
        let totalMaterialQty = 0
        let totalMaterialAmount = 0
        this.materials.forEach((materialItem, materialIndex) => {
          const actualMaterial = this.$store.state.incentiveGrant.commonObj.agMaterialList.find(obj => obj.value === parseInt(materialItem.material_id))
          const materialQty = this.form.details[index].materials[materialIndex].quantity ? parseFloat(this.form.details[index].materials[materialIndex].quantity) : 0
          const materialAmount = materialQty > 0 ? parseFloat(materialQty) * actualMaterial.price : 0
          this.form.details[index].materials[materialIndex].quantity = materialQty
          this.form.details[index].materials[materialIndex].amount = materialAmount
          totalMaterialQty += materialQty
          totalMaterialAmount += materialAmount
        })
        const totalCropMaterial = parseFloat(this.form.details[index].crop_quantity) + totalMaterialQty
        this.form.details[index].cash_tk = parseFloat(totalFarmer * parseFloat(this.policy.amount_per_farmer))
        const cashTk = this.form.details[index].cash_tk ? parseFloat(this.form.details[index].cash_tk) : 0
        const transportCost = parseFloat(this.policy.transport_cost) * totalCropMaterial
        const otherCost = parseFloat(this.policy.others_cost) * totalCropMaterial
        this.form.details[index].transport_cost = transportCost
        this.form.details[index].other_cost = otherCost
        this.form.details[index].material_quantity = totalMaterialQty
        this.form.details[index].material_amount = totalMaterialAmount
        this.form.details[index].total_amount = this.form.details[index].crop_amount + totalMaterialAmount + cashTk + transportCost + otherCost
        this.getTotalRowInfo()
      },
      calculateAssociateInfo2 (index) {
        this.$nextTick(function () {
          const circularItem = this.$store.state.incentiveGrant.commonObj.circularList.find(item => item.value === parseInt(this.form.circular_id))
          const perFarmerAmount = parseFloat(circularItem.amount) / parseInt(circularItem.total_farmer)
          this.form.details[index].total_amount = parseInt(this.form.details[index].total_farmer) * perFarmerAmount
          let upazilaTotalFarmer = 0
          // let upazilaTotalAmount = 0
          this.form.details.forEach((item, index) => {
            upazilaTotalFarmer += item.total_farmer ? parseInt(item.total_farmer) : 0
            // upazilaTotalAmount += item.amount ? parseFloat(item.amount) : 0
          })
          const maxTotalFarmer = parseInt(this.districtAllocation.total_farmer) - (upazilaTotalFarmer - parseInt(this.form.details[index].total_farmer))
          if (upazilaTotalFarmer > parseInt(this.districtAllocation.total_farmer)) {
            this.$toast.error({
              title: 'Error',
              message: 'Total farmer of upazila can n\'t be larger than total farmer of district',
              color: '#D6E09B'
            })
            this.form.details[index].total_farmer = maxTotalFarmer
            this.form.details[index].total_amount = parseInt(maxTotalFarmer) * perFarmerAmount
          }
          // const maxTotalAmount = parseInt(this.districtAllocation.amount) - (upazilaTotalAmount - parseInt(this.form.details[index].amount))
          // if (upazilaTotalAmount > parseInt(this.districtAllocation.amount)) {
          //   this.$toast.error({
          //     title: 'Error',
          //     message: 'Total farmer of upazila can n\'t be larger than total farmer of district',
          //     color: '#D6E09B'
          //   })
          //   this.form.details[index].amount = maxTotalAmount
          //   return false
          // }
          this.getTotalRowInfo()
        })
      }
    }
}
</script>
<style scoped>
    .table-bordered th, .table-bordered td{
        border: 1px solid #000 !important;
    }
</style>
