// Incentive Allocation routes
const distAllo = 'dae/allocation/district-allocation/'
export const circularCropMaterials = distAllo + 'crop-materials'
export const distAlloList = distAllo + 'list'
export const distAlloShow = distAllo + 'show'
export const distAlloStore = distAllo + 'store'
export const distAlloUpdate = distAllo + 'update'
export const distAlloStatusUpdate = distAllo + 'status-update'
export const distAlloForward = distAllo + 'forward'
export const distAlloReqList = distAllo + 'request-list'
export const distAlloPolicyInfo = distAllo + 'distribution-policy-info'
export const designationWiseUser = '/dae/subsidy/bill-prepare/get-user'

// Nothi api
export const nothi = 'nothi/district-allocation/'
export const nothiSend = nothi + 'send'
export const nothiReSend = nothi + 're-send'

// Upazilla incentive allocation preparation
const upazillaAllo = 'dae/allocation/upazilla-allocation/'
export const districtAllocationData = upazillaAllo + 'district-wise-allocation'
export const upazillaAlloList = upazillaAllo + 'list'
export const upazillaAlloStore = upazillaAllo + 'store'
export const upazillaAlloShow = upazillaAllo + 'show'
export const upazillaAlloUpdate = upazillaAllo + 'update'
export const upazillaAlloStatusUpdate = upazillaAllo + 'status-update'
export const upazillaAlloForward = upazillaAllo + 'forward'
export const upazillaAlloReqList = upazillaAllo + 'request-list'
